import './App.css';
import {Route, Routes} from "react-router-dom";
import CGV from "./pages/CGV.tsx";
import Home from "./pages/Home.tsx";
import MentionLegales from "./pages/MentionLegales.tsx";
import Confidentialite from "./pages/Confidentialite.tsx";

function App() {
    return (
        <div className="app-container">
            <header className="header">
                <a href="/"><img src="logo.png" alt="Logo" className="logo"/></a>
                <h1 className="site-title">The Dropshipping & E-Commerce Agent</h1>
            </header>

            <Routes>
                <Route path="/">
                    <Route index element={<Home/>}/>
                    <Route path="mentions-legales" element={<MentionLegales/>}/>
                    <Route path="politique-confidentialite" element={<Confidentialite/>}/>
                    <Route path="cgv" element={<CGV/>}/>
                    <Route path="*" element={<Home/>}/>
                </Route>
            </Routes>

            <footer className="App-footer">
                <div className="Footer-Links">
                    <p><a href="/politique-confidentialite">Politique de Confidentialité</a></p>
                    <p>|</p>
                    <p><a href="/mentions-legales">Mentions légales</a></p>
                    <p>|</p>
                    <p><a href="/cgv">Conditions Générales de Ventes</a></p>
                </div>
                <div className="Footer-Copyright">
                    <p>Copyright 2022 © The Dropshipping & E-Commerce Assistant. Tous droits réservés.</p>
                </div>
            </footer>
        </div>
    );
}

export default App;
