export default function CGV() {
    return (
        <div>
            <h2 className="pageTitle">Conditions Générales de Vente</h2>

            <p>Ce site est édité par la société Jose Luis Costa Brava - TheDEABot, 45 Rue Montmartre, 75002,
                Paris, France</p>
            <p>Les présentes conditions générales de vente s'appliquent à toutes les transactions conclues par le biais
                du site thedeabot.com.</p>

            <p>Est considéré comme « client » toute personne physique ou morale réalisant auprès de TheDEABot une
                commande validée via notre plateforme de paiement sécurisée ou par virement bancaire.</p>
            <p>Est considéré comme « produit » tout livre physique, enregistrement audio sous tout format, vidéo en
                ligne ou téléchargeable sous tout format, fichier de tout ordre.</p>

            <h3>Préambule</h3>
            <p>Toute prise de commande au titre d'un produit ou service figurant au sein de ce site et de ses sites
                thématiques suppose la consultation préalable des présentes conditions générales.</p>
            <p>Les présentes conditions générales de vente expriment l'intégralité des obligations des parties. En ce
                sens,
                le Client est réputé accepter sans réserve l'intégralité des dispositions prévues dans ces conditions
                générales. Aucune condition générale ou spécifique figurant dans les documents envoyés ou remis au
                client ne
                pourra s'intégrer aux présentes, dès lors que ces documents seraient incompatibles avec ces conditions
                générales de vente.</p>

            <p>TheDEABot se réserve le droit de modifier à tout moment les présentes conditions générales de vente. La
                dernière version publiée sur le site fait foi. Il est donc recommandé au Client de prendre connaissance
                des
                présentes conditions générales de vente lors de chaque commande.</p>

            <p>Le Client reconnaît être en pleine possession de ses capacités juridiques, physiques, mentales et
                intellectuelles pour comprendre et appliquer les présentes conditions générales de vente.</p>

            <h3>Article 1er : Généralités</h3>
            <p>Les présentes conditions générales de vente ont pour objet de définir les droits et obligations des
                parties
                dans le cadre de la vente en ligne de biens et services proposés par la société éditrice au Client. Ces
                conditions générales sont susceptibles de modification à tout moment, la dernière version en ligne
                faisant
                foi.</p>
            <p>Ce site est destiné à un public majeur (18 ans ou plus au moment de la commande), aussi bien particulier
                que
                professionnel. Si un Client professionnel commande l’un des produits ou services proposés ici, ce
                dernier
                s’engage à ne pas le revendre sans accord écrit préalable de la société éditrice.</p>

            <h3>Article 2 : Droits d’utilisation</h3>
            <p>Ce site ainsi que tout droit qui lui est rattaché restent la propriété exclusive de la société éditrice.
                Toutes les marques, logos, éléments graphiques ou autres reproduits sur ce site ainsi que sur les pages
                auxquels celui-ci permet l'accès par l'intermédiaire de liens hypertextes, sont la propriété exclusive
                de
                leurs titulaires.</p>
            <p>Toute reproduction, totale ou partielle, non autorisée de ces marques, logos et signes constitue une
                contrefaçon passible de sanctions pénales.</p>
            <p>Le Client s’interdit de communiquer à quiconque, directement ou indirectement, les informations de toute
                nature concernant la société TheDEABot, et notamment les informations commerciales, industrielles,
                techniques, financières ou sur sa clientèle, qui lui auront été communiquées par cette dernière ou dont
                il
                aurait eu connaissance à l’occasion des Services.</p>
            <p>Le présent engagement de confidentialité entre en vigueur dès la passation de commande par le Client et
                est
                souscrit sans limitation de durée. Le Client ne sera délié de son engagement de confidentialité que sur
                autorisation écrite, préalable et expresse de la société TheDEABot.</p>
            <p>Tous les logiciels utilisés sur le site et ceux auxquels ils permettent l'accès, ainsi que les textes,
                commentaires, illustrations, musiques, vidéos ou images reproduits sur les sites et sur ceux auxquels
                ils
                permettent l'accès font l'objet d'un droit d'auteur et leur reproduction non autorisée par leur auteur
                constitue une contrefaçon passible de sanctions pénales. Vous ne pouvez pas le diffuser, le copier ou le
                reproduire par quelque procédé que ce soit. Vous pouvez cependant en réaliser des impressions et copies
                pour
                votre usage personnel.</p>
            <p>Vous reconnaissez que ce site n'est pas un site à vocation médicale, thérapeuthique, légal ou fiscal mais
                a
                pour seul objectif de proposer une assistance à votre développement personnel ou au développement de
                votre
                entreprise. Les informations fournies ne sont en aucun cas des conseils professionnels médicaux,
                psychologiques, financiers, légaux, ni dans aucun autre domaine. Si vous avez des problèmes physiques,
                psychologiques, ou avant de prendre toute décision, vous devez consulter un professionnel assermenté.
                Par
                conséquent, vous renoncez à toute poursuite éventuelle concernant les effets que pourraient avoir, ou ne
                pas
                voir, l'utilisation des produits et informations présentés sur ce site.
                Autrement dit, la responsabilité de l'auteur, de l'éditeur et de tout autre partenaire ne pourrait être
                engagée pour tout résultat se produisant ou ne se produisant pas suite à l'utilisation des informations
                fournies ici. En achetant sur ce site, vous acceptez que leur responsabilité soit limitée au prix du
                produit
                que vous avez acheté, dans le cadre du droit de rétractation.</p>

            <h3>Article 3 : Documents contractuels</h3>
            <p>Le présent contrat est formé par les documents contractuels suivants, présentés par ordre hiérarchique
                décroissant : les présentes conditions générales de vente ; le bon de commande (action de valider le
                paiement) ; la facture ; les informations de présentation du produit.</p>
            <p>En cas de contradiction entre les dispositions contenues dans les documents de rang différent, les
                dispositions du document de rang supérieur prévaudront.</p>

            <h3>Article 4 : Durée</h3>
            <p>Les présentes conditions générales de vente entrent en vigueur à la date de l’accès au site par le
                Client.</p>
            <p>Les présentes conditions générales de vente sont conclues pour la durée nécessaire à la fourniture des
                biens
                et services souscrits, jusqu'à l'extinction des garanties dues par la société éditrice.</p>

            <h3>Article 5 : Signature électronique</h3>
            <p>Le clic nécessaire à la confirmation de commande lors d’un achat de produit ou service sur ce site
                constitue
                une signature électronique qui a, entre les parties, la même valeur qu'une signature manuscrite.</p>

            <h3>Article 6 : Confirmation de commande</h3>
            <p>Toutes les informations feront l’objet d’un message personnalisé transmis par voie électronique à
                l’adresse
                e-mail communiquée par le Client (bon de commande physique, adresse du paiement virtuel).</p>

            <h3>Article 7 : Preuves de transaction</h3>
            <p>Les registres informatisés, conservés dans les systèmes informatiques de la société éditrice, ou tout
                prestataire de services de son choix, dans des conditions raisonnables de sécurité, seront considérés
                comme
                les preuves des communications, des commandes et des paiements intervenus entre les parties. L'archivage
                des
                bons de commande et des factures est effectué sur un support fiable et durable pouvant être produit à
                titre
                de preuve.</p>

            <h3>Article 8 : Information sur les produits ou services</h3>
            <p>La société éditrice présente sur son site les produits ou services à vendre avec les caractéristiques
                nécessaires qui permettent au Client potentiel de connaître avant la prise de commande définitive les
                caractéristiques essentielles des produits qu'il souhaite acheter.</p>

            <h3>Article 9 : Disponibilité des produits et services proposés</h3>
            <p>Les offres présentées sur ce site ne sont valables que dans la limite des stocks disponibles.
                En cas d'indisponibilité du produit commandé, notamment du fait de nos fournisseurs, le Client en sera
                informé au plus tôt et aura la possibilité d'annuler sa commande. Le Client pourra alors demander le
                remboursement des sommes versées.</p>

            <h3>Article 10 : Prix</h3>
            <p>Les prix sont indiqués en une seule devise (Euro) et TTC. Les prix sont valables qu’à la date
                d’enregistrement de la commande.
                Le paiement de la totalité du prix doit être réalisé lors de la commande, sauf en cas de paiement
                échelonné
                en plusieurs fois. Le règlement en plusieurs fois pourra être d'un montant supérieur au paiement en une
                fois, du fait des frais de traitement administratifs. A aucun moment, les sommes versées ne pourront
                être
                considérées comme des arrhes ou des acomptes.</p>

            <h3>Article 11 : Mode de paiement</h3>
            <p>Pour régler sa commande, le Client dispose, à son choix, de l'ensemble des modes de paiement proposés sur
                le
                site. Le Client garantit la société éditrice qu'il dispose des autorisations éventuellement nécessaires
                pour
                utiliser le mode de paiement choisi par lui, lors de la validation de sa commande.
                La société éditrice se réserve le droit de suspendre toute gestion de commande et toute livraison en cas
                de
                refus d'autorisation de paiement par carte bancaire de la part des organismes officiellement accrédités
                ou
                en cas de non-paiement.</p>
            <p>La société éditrice se réserve notamment le droit de refuser d'effectuer une livraison ou d'honorer une
                commande émanant d'un Client qui n'aurait pas réglé totalement ou partiellement une commande précédente
                ou
                avec lequel un litige de paiement serait en cours d'administration.
                En cas de paiement en plusieurs fois, l'intégralité des sommes contractées restent dues et seront
                placées si
                nécessaire en recouvrement.</p>
            <p>Si le client choisit de payer le produit en plusieurs fois, il ne s'agit en aucun cas d'un abonnement.
                L'intégralité des sommes contractées restent dues. Si le client demande une annulation des prélèvements
                auprès de sa banque afin de ne pas honorer ses mensualités, la société éditrice se réserve le droit de
                mettre en œuvre des actions juridiques en vue d'être réglées intégralement sur les montants auxquels le
                client s'est engagé.</p>

            <h3>Article 12 : Modalités de livraison</h3>
            <p>La société éditrice se réserve le droit de livrer ses produits sous plusieurs formes :</p>
            <p>Dans une zone membre privé, le client réservera un accès avec identifiant et mot de passe pour ce
                connecter
                à la zone membre. Le client recevra un module par semaine sous forme de vidéo.</p>
            <p>Les produits sont aussi livrés directement sur l’adresse email que le client a fourni.
                A l’adresse postale fournie par client</p>

            <h3>Article 13 : Délais de livraison</h3>
            <p>Pour un produit électronique, le délai de livraison est immédiat.</p>

            <h3>Article 14 : Droit de rétractation</h3>
            <p>Le
                consommateur dispose d'un délai de quatorze jours pour exercer son droit de rétractation d'un contrat
                conclu à distance, à la suite d'un démarchage téléphonique ou hors établissement, sans avoir à motiver
                sa
                décision ni à supporter d'autres coûts que ceux prévus aux articles L. 221-23 à L. 221-25.</p>
            <p>Le délai mentionné au premier alinéa court à compter du jour :</p>
            <p>1° De la conclusion du contrat, pour les contrats de prestation de services et ceux mentionnés à
                l'article
                L. 221-4 ;</p>
            <p>2° De la réception du bien par le consommateur ou un tiers, autre que le transporteur, désigné par lui,
                pour
                les contrats de vente de biens. Pour les contrats conclus hors établissement, le consommateur peut
                exercer
                son droit de rétractation à compter de la conclusion du contrat.</p>
            <p>La réglementation exclut le délai de rétractation légal lors de la “fourniture d’enregistrements audio ou
                vidéo, ou de logiciels informatiques lorsqu’ils ont été descellés par le consommateur”. Le délai de
                rétractation légal est donc nul à partir du moment où le produit commandé est téléchargé et/accessible.
                Source : Article L121-20-2 du code de la consommation.</p>
            <p>Lorsqu’une garantie est proposée sur la page de présentation d’un produit, celle-ci est valide dans le
                cadre
                décrit par sur la page de présentation du produit.</p>
            <p>Certaines garanties ne prennent effet si et seulement si le client effectue bien les actions détaillées
                dans
                le produit acheté (mise en œuvre de 100% de la formation).</p>
            <p>TheDEABot demandera au Client de présenter les preuves de la réalisation de ces démarches
                nécessaires.</p>

            <h3>Article 15 : Protection des données personnelles</h3>
            <p>Les données fournies par les usagers se trouvent sur un fichier automatisé et elles sont uniquement
                utilisées aux fins déjà décrites. Ces données sont recueillies à travers des formulaires prévus à cet
                effet,
                qui contiennent uniquement des champs indispensables pour offrir le service requis par les usagers. Les
                données personnelles ne sont pas cédées à de tierces personnes.</p>
            <p>Les usagers peuvent exercer les droits d'accès, rectification, annulation et opposition des données
                personnelles à travers un message à l'adresse suivante: support@thedeabot.com. Les représentants légaux
                des
                usagers qui peuvent accréditer leur condition de représentant par le biais d'un document qui en fait foi
                peuvent également exercer ces droits.</p>
            <p>Ce site est également conçu pour être particulièrement attentif aux besoins de nos clients. C'est pour
                cela
                que nous pourrons faire usage de cookies. Le cookie a pour but de signaler votre passage sur notre site.
                Les
                cookies ne sont donc utilisés par le site que dans le but d'améliorer le service personnalisé qui vous
                est
                destiné.</p>

            <h3>Article 17 : Contenu fourni par les membres des sites</h3>
            <p>Les membres des sites peuvent être amenés à proposer du contenu : commentaires, articles, et toute autre
                contribution.</p>
            <p>Les membres sont réputés détenir tous les droits sur les contenus fournis. En les publiant sur ce site,
                ils
                en concèdent les droits d'utilisation illimités à l'éditeur, y compris les droits d'adaptation, de
                traduction, de curation et de diffusion sous toute forme.</p>

            <h3>Article 18 : Participation aux évènements</h3>
            <p>Les participants aux événements sont informés que des enregistrements audios ou vidéos peuvent être
                réalisés. En participant aux évènements, ils concèdent de fait à l'éditeur la licence illimitée
                d'utilisation de toute image et de tout enregistrement audio ou vidéo.</p>

            <h3>Article 19 : Divulgation</h3>
            <p>Ce site internet utilise des cookies et peut également avoir recours aux services de fournisseurs tiers
                qui
                déploient des cookies dans le cadre de leurs services.</p>
            <p>Nous adoptons la définition des cookies au sens établi par la directive européenne 2002/58/ce relative au
                traitement des données personnelles et à la protection de la vie privée dans le secteur des
                communications
                (telle qu’amendée par la directive 2009/136/ce) et fournissons des informations sur les différentes
                catégories de cookies ci-dessous, ainsi que des informations sur les types de cookies que nous déployons
                sur
                nos sites internet. En continuant d’utiliser ce site internet, vous acceptez l’installation de cookies
                sur
                tout périphérique utilisé pour accéder à notre/nos site(s) internet.</p>

            <h3>Article 20 : Droit applicable</h3>
            <p>Jose Luis Costa Brava / The DEA Bot<br/>
                45 Rue Montmartre<br/>
                75002 Paris<br/>
                France<br/>
            </p>
            <p>Paiements par carte enregistrés au nom de : <br/>
                Les paiements par cartes sont effectués par la société Stripe INC.<br/>
                Contact :<br/>
                Merci de nous écrire un email à : support@thedeabot.com<br/>
                Hébergeur : Amazon Web Services<br/></p>

        </div>
    );
}