export default function MentionLegales() {
    return (
        <div>
            <h2 className="pageTitle">Mention Legales</h2>

            <h3>Editeur du site :</h3>
            <p>Société Jose Luis Costa Brava / TheDEABot<br/>
                support@thedeabot.com
            </p>

            <h3>Siège social :</h3>
            <p>Jose Luis Costa Brava / The DEA Bot<br/>
                45 Rue Montmartre<br/>
                75002 Paris<br/>
                France</p>

            <h3>Hébergement :</h3>
            <p>OVH Cloud</p>

            <h3>Pour toutes questions et réclamations :</h3>
            <a href="mailto:support@thedeabot.com">support@thedeabot.com</a>

        </div>
    );
}