export default function Confidentialite() {
    return (
        <div>
            <h2 className="pageTitle">Politique de confidentialité</h2>

            <h3>POLITIQUE DE CONFIDENTIALITE - COOKIES</h3>

            <p>Dernière mise à jour de la Politique de confidentialité : Le 15 novembre 2024</p>
            <p>Bienvenue sur http://thedeabot.com/</p>

            <p>En vous connectant à ce site, en vous inscrivant au programme de formation TheDEABot et en y participant,
                vous nous transmettez des informations personnelles. Le responsable de traitement de vos données à
                caractère personnel est la société Jose Luis Costa Brava - TheDEABot, dont le siège social est situé 45
                Rue Montmartre, 75002 Paris, France (ci-après « nous »).</p>
            <p>La protection de vos données personnelles est une priorité pour TheDEABot, et cette collecte
                d’information se limite au strict nécessaire, conformément au principe de minimisation des données. Vos
                données ne seront pas transmises à des tiers à des fins de prospection commerciale, que ce soit à titre
                gratuit ou onéreux.</p>
            <p>Pour toute précision ou réclamation, n’hésitez pas à nous contacter par mail à l’adresse
                support@thedeabot.com pour toute demande.</p>

            <h3>QUELLES SONT LES CATEGORIES DE DONNEES QUI FONT L’OBJET D’UN TRAITEMENT INFORMATISE ?</h3>

            <p>Lorsque vous naviguez sur le Site, des données recueillies grâce aux cookies et technologies similaires
                utilisés (notamment adresses IP, logs de connexion, données de fréquentation, terminal utilisé, langue
                du navigateur).</p>
            <p>Les informations d’état civil et de contact (nom ; prénom ; adresse mail ; adresse postale ; numéro de
                téléphone…) nous permettront de vous identifier et de communiquer avec vous ; Vos messages et nos
                réponses pourront également faire l’objet d’un traitement ;</p>
            <p>Nous serons également amenés à traiter des informations relatives à la relation contractuelle et
                commerciale (notamment les détails sur la ou les formations commandées) que vous pourriez nouer avec
                nous ainsi que des informations bancaires (coordonnées bancaires, numéros de carte, cryptogramme) et
                transactionnelles (date de la transaction, montant, numéro de commande).</p>
            <p>Dans le cadre de(s) formations(s), nous serons amenés à collecter et traiter des données de suivi d’une
                formation donnée (consultation des modules, temps passé, indices de progression, données de
                participation aux webinaires données d’inscription et de participation aux séances de coaching groupées
                ou privées,…).</p>

            <h3>DANS QUELS BUTS ET SUR QUELS FONDEMENTS LEGAUX ?</h3>

            <p>Ces traitements de données participent à l'exécution de la relation contractuelle qui nous unit et/ou que
                vous souhaitez nouer avec nous, puisque les données personnelles que nous collectons et traitons sont
                nécessaires à la réalisation des prestations et/ou services sollicités au titre de nos CGV.</p>
            <p>Ces traitements sont également nécessaires à protéger nos intérêts légitimes, notamment en nous
                permettant de faire de la prospection commerciale dans le cadre de notre activité professionnelle, de
                conserver la preuve des transactions effectuées et/ou le cas échéant procéder au recouvrement de
                factures impayées.</p>
            <p>Les objectifs poursuivis par les traitements automatisés des données susmentionnées sont les suivants
                :</p>
            <p>Identification des personnes utilisant le Site pour bénéficier des services de formation en ligne,
                s’inscrire et accéder aux prestations afférentes ;</p>
            <p>Assurer la gestion du compte client de la personne concernée ou de la personne morale que la personne
                concernée représente, et l’exécution des opérations de paiements faites à sa demande ;</p>
            <p>Effectuer les opérations relatives à la gestion des fichiers concernant : les contrats ; les commandes ;
                les livraisons ; les factures ; la comptabilité et le suivi de la relation commerciale ;</p>
            <p>Gestion de la relation avec les prospects et clients et des avis des personnes sur des produits, services
                ou contenus ;</p>
            <p>Traitement des questions et des éventuelles réclamations des personnes et la gestion des demandes de
                droit d'accès, de rectification et d'opposition ;</p>
            <p>Respect les modalités d’accès en ligne aux comptes (et gestion des éventuelles procédures
                d’authentification) ;</p>
            <p>Analyse et suivi de la progression des clients de la formations ;</p>
            <p>Exécution des paiements ;</p>
            <p>Elaboration de statistiques commerciales et publicité, y compris sur les réseaux sociaux ;</p>
            <p>Prospection et/ou envoi d’informations (newsletter), ce qui inclut la relance des prospects (y compris en
                cas d’abandon de commande), la gestion des opérations techniques de prospection, la sélection des
                personnes pour réaliser des actions de fidélisation, de prospection, de sondage, de test produit et
                promotion ainsi que la réalisation d’opérations de sollicitation ;</p>
            <p>Prévention et lutte contre la fraude et aux moyens de paiement et notamment contre la fraude à la carte
                bancaire ;</p>
            <p>Gestion des impayés et du contentieux ;</p>
            <p>Amélioration
                du Site et des offres, en particulier pour la formation Facebook Master</p>

            <h3>INFORMATION SUR LES COOKIES</h3>

            <p>Vous êtes informé que nous sommes est susceptible de déposer des cookies sur votre terminal. Le cookie
                enregistre des informations relatives à la navigation sur nos sites (les pages que vous avez consultées,
                la date et l'heure de la consultation...) que nous pourrons lire lors de vos visites ultérieures. La
                durée maximale de conservation des cookies est de 13 mois après leur premier dépôt dans votre terminal.
                La durée de vie des cookies n’est pas prolongée à chaque visite.</p>
            <p>Les
                cookies peuvent être utilisés pour des fins statistiques notamment pour optimiser les services rendus, à
                partir du traitement des informations concernant la fréquence d'accès, la personnalisation des pages
                ainsi que les opérations réalisées et les informations consultées. Ils peuvent également être utilisés à
                des fins publicitaires, notamment pour vous proposer du contenu ciblé dans les bannières et encarts sur
                internet. Certaines fonctionnalités du site tels que les lecteurs vidéo ou des contenus interactifs sont
                susceptibles de faire appel à des services proposés par des tiers et de déposer des cookies leur
                permettant d’identifier votre consultation du contenu.</p>
            <p>Vous
                êtes donc informé que nous pouvons avoir recours à des cookies, et nous y autorisez en validant le
                bandeau dédié. Si vous ne souhaitez pas que des cookies soient utilisés sur votre terminal, la plupart
                des navigateurs vous permettent de désactiver les cookies en passant par les options de réglage.
                Toutefois, vous êtes informé que certains services sont susceptibles de ne plus fonctionner
                correctement.</p>

            <h3>QUELS SONT VOS DROITS ET COMMENT LES EXERCER</h3>

            <p>Vous
                disposez d’un droit d’accès aux données vous concernant, de rectification ou d’effacement,
                d’interrogation, de limitation du traitement de vos données, de portabilité, et d’effacement (plus
                d’infos ici), dans les limites posées par la réglementation et notamment le RGPD.
            </p><p>Vous
            disposez également du droit de vous opposer à tout moment, pour des raisons tenant à votre situation
            particulière, à un traitement des données à caractère personnel ayant comme base juridique notre intérêt
            légitime, ainsi que d’un droit d’opposition à la prospection commerciale en cliquant sur le lien de
            désinscription de la newsletter.
        </p><p>Les
            demandes d’exercice de vos droits sont à adresser par voie électronique à l’adresse : support@thedeabot.com.
            Vous pouvez également introduire une réclamation auprès de l’autorité de contrôle et de protection des
            données personnelles sont vous relevez (par exemple, en France, la CNIL).
        </p>
            <p>A
                toutes fins utiles, il est précisé qu’à compter de l’exercice du droit à l’effacement, de s’opposer au
                traitement, ou encore de retirer son consentement, le bon fonctionnement du site et/ou des services de
                formation (dont la plateforme de e.learning) pourra être perturbé voire interrompu. Par exemple, si ces
                droits sont exercés au moment de la commande de prestations ou services, alors ladite commande ne pourra
                être effectuée.

                <h3>PENDANT COMBIEN DE TEMPS MES DONNEES SONT ELLES CONSERVEES ?</h3>

            </p>
            <p>Si
                vous ne vous êtes pas authentifié sur le Site ou que vous n’avez pas eu un comportement actif (par
                exemple, en cliquant sur un lien) pendant une période de trois ans, vous pourrez recevoir un e-mail vous
                invitant à vous connecter dans les plus brefs délais, sans quoi vos données seront supprimées de nos
                bases de données. Nos communications électroniques relevant de la prospection commerciale ou newsletter
                comporteront un lien de désinscription.
            </p><p>En
            vertu de nos obligations légales, certains documents relevant de notre fonctionnement interne et comportant
            des informations personnelles (bons de commande, contrats, factures…) feront l’objet d’un archivage. En tout
            état de cause, les données à caractère personnel faisant l'objet d'un traitement ne sont pas conservées
            au-delà du temps nécessaire à l'exécution des obligations définies lors de la conclusion du contrat, ou en
            posées par la législation en vigueur. Au-delà, elles pourront être anonymisées et conservées à des fins
            exclusivement statistiques.
        </p><p>Les
            données recueillies grâce aux cookies et technologies similaires sont conservées pour la durée strictement
            nécessaire à la réalisation des finalités visées ci-avant, sans aller au-delà de 13 mois.
        </p>
            <p>Des
                moyens de purge de données sont mis en place afin d'en prévoir la suppression effective dès lors que la
                durée de conservation ou d'archivage nécessaire à l'accomplissement des finalités déterminées ou
                imposées est atteinte.

                <h3>NOS ENGAGEMENTS EN MATIERE DE SOUS-TRAITANCE, TRANSFERT, COMMUNICATION A DES TIERS</h3>

            </p>
            <p>Vos
                données nominatives sont à usage interne, elles sont strictement confidentielles et ne peuvent être
                divulguées à des tiers, sauf en cas d’accord exprès ou si vous avez décidé de les rendre publiques.
            </p><p>En
            cas de communication de vos données personnelles à un tiers, quelle que soit sa qualité, nous nous
            assurerons préalablement que ce dernier est tenu d'appliquer des conditions de confidentialité identiques
            aux nôtres.
        </p><p>De
            plus, nous nous engageons à (i) ce que tout sous-traitant présente des garanties contractuelles suffisantes
            et appropriées pour respecter vos droits, afin que le traitement réponde aux exigences du RGPD et (ii) à
            respecter les dispositions du RGPD applicables aux transferts des données. Notamment, notre prestataire
            informatique ClickFunnels est adhérent au Privacy Shield.
        </p><p>Sur
            la base de nos obligations légales, vos données personnelles pourront être divulguées en application d'une
            loi, d'un règlement ou en vertu d'une décision d'une autorité réglementaire ou judiciaire compétente.</p>

            <h3>NOS ENGAGEMENTS EN MATIERE DE SECURITE INFORMATIQUE</h3>

            <p>Nous
                nous engageons à mettre en œuvre toutes les mesures techniques et organisationnelles appropriées grâce à
                des moyens de sécurisation physiques et logistiques afin de garantir un niveau de sécurité adapté au
                regard des risques d'accès accidentels, non autorisés ou illégaux, de divulgation, d'altération, de
                perte ou encore de destruction des données personnelles vous concernant.
            </p><p>Dans
            l'éventualité où nous prendrions connaissance d'un accès illégal aux données personnelles vous concernant
            stockées sur nos serveurs ou ceux de nos prestataires, ou d'un accès non autorisé ayant pour conséquence la
            réalisation des risques identifiés ci-dessus, nous nous engageons à :
        </p><p>Vous
            notifier l'incident dans les plus brefs délais si cela est susceptible d'engendrer un risque élevé pour vos
            droits et libertés ;
        </p><p>Examiner
            les causes de l'incident ;
        </p><p>Prendre
            les mesures nécessaires dans la limite du raisonnable afin d'amoindrir les effets négatifs et préjudices
            pouvant résulter dudit incident
        </p><p>En
            aucun cas les engagements définis au point ci-dessus ne peuvent être assimilés à une quelconque
            reconnaissance de faute ou de responsabilité quant à la survenance de l'incident en question.</p>

            <h3>DROIT APPLICABLE ET LANGUE</h3>

            <p>La
                présente Politique de Confidentialité est régie par le droit de l’Union européenne. Elles sont rédigées
                en français. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français
                ferait foi en cas de litige. La nullité d'une clause n'entraîne pas la nullité de la Politique de
                confidentialité. L'inapplication temporaire ou permanente d'une ou plusieurs clauses des présentes ne
                saurait valoir renonciation de sa part aux autres clauses des présentes qui continuent à produire leurs
                effets.</p>


        </div>
    );
}