export default function Home() {
    return (
        <>
            <section className="hero">
                <h2 className="hero-subheadline">
                    Débloquez Votre Succès en E-commerce
                </h2>
                <p className="hero-headline">
                    Trouvez Votre Produit Gagnant en 30 Minutes !
                </p>
                <p className="hero-text">
                    Boostez votre succès en e-commerce et dropshipping grâce à <b>notre
                    accompagnement sur-mesure et nos stratégies éprouvées.</b>
                </p>
                <a href="https://t.me/TheDEABot" target="_blank" className="hero-cta">
                    Commencez Maintenant
                </a>
            </section>

            <section className="features">
                <h3 className="section-title">Nos atouts uniques pour propulser votre business</h3>
                <div className="feature-grid">
                    <div className="feature-card">
                        <div className="feature-icon">🛠️</div>
                        <h4>Accès à des outils et ressources premium</h4>
                        <p>
                            <ul>
                                <li><b>Accès à nos stratégies de recherche produit et aux marchés inexploités</b></li>
                                <li><b>Base de données produits gagnants</b> mise à jour régulièrement.</li>
                                <li><b>Guides stratégiques</b> pour créer des publicités efficaces et optimiser vos
                                    fiches
                                    produits.
                                </li>
                                <li>Accès à nos <b>agents</b> pour <b>optimiser vos marges et délais de livraison</b>.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">📈</div>
                        <h4>Un coach dédié, disponible 7j/7</h4>
                        <p>
                            Profitez de l'expertise d'un professionnel expérimenté pour vous guider à chaque étape. </p>
                        <p>Que
                            ce soit pour trouver un produit gagnant, optimiser vos ventes ou surmonter vos défis, votre
                            coach est là pour répondre à toutes vos questions et vous accompagner vers la réussite.
                        </p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">🌐</div>
                        <h4>Une communauté privée exclusive</h4>
                        <p>
                            Rejoignez un réseau international d'entrepreneurs à succès. </p><p>Échangez, partagez vos
                        expériences et inspirez-vous d'autres passionnés, dans un environnement bienveillant et
                        motivant.
                    </p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">🖥️</div>
                        <h4>Support technique en continu</h4>
                        <p>
                            Des soucis avec votre site ou vos outils marketing ? </p><p>Notre équipe technique est
                        disponible
                        pour vous aider à les résoudre rapidement.

                    </p>
                    </div>
                </div>
            </section>

            <section className="footer">
                <p>
                    <b>Rejoignez-nous dès maintenant et donnez un nouvel élan à votre business e-commerce !</b></p>
                <p>
                    👉 <b>Réussir n’a jamais été aussi simple.</b> Nous sommes là pour vous aider à atteindre vos
                    objectifs
                    rapidement et efficacement.
                </p>
                <p style={{fontSize: "1.05em"}}><b>Seulement 30€ TTC par mois !</b></p>
                <a href="#pay" className="footer-cta">
                    Payez Maintenant
                </a>
            </section>
        </>
    );
}